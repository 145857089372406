main {
	position: relative;
	padding: 2em 4em 2em 4em;
	background: #3a444a;
	text-align: left;
    color: #fff;
    letter-spacing: 0.04em;
    line-height: 1.5em;
    font-family: $ff-sans;
    font-weight: 400;    
    h1 {
        padding-bottom: 0.25em;
        font-size: 2em;
        font-weight: 500;
        line-height: 1.2;
    }
    h2 {
        font-size: 1.5em;
        font-weight: 500;
        line-height: 1.2;
    }
    h3 {
        font-size: 1.2em;
        font-weight: 400;
        line-height: 1.2;
    }

    p {
        font-size: 1.1em;
        margin: 1rem 0 1rem 0;
    }
    a {
        -moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
        -webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
        -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
        border-bottom: dotted 1px rgba(255, 255, 255, 0.7);
        color: #bec9cf;
        text-decoration: none;
    }
    a:hover {
        border-bottom-color: transparent;
        color: #ffffff !important;
    }
    code {
        background: rgba(255, 255, 255, 0.075);
        white-space: nowrap;
        border-radius: 5px;
        border: solid 0px;
        font-family: "Courier New", monospace;
        font-size: 0.9em;
        padding: 0.25em 0.65em;
    }
    pre {
        background: rgba(255, 255, 255, 0.025);
        border-radius: 5px;
        white-space: pre-wrap;
        border: solid 2px rgba(255, 255, 255, 0.125);
        font-family: "Courier New", monospace;
        font-size: 0.9em;
        margin: 0 0.25em;
        padding: 0.25em 0.65em;  
    }
    hr {
        margin-bottom: 2em;
        border-bottom: solid 2px rgba(255, 255, 255, 0.125);
    }
    ul {
        margin: 0.5rem;
        list-style: none;
        li::before {
            content: "- ";
        }
    }
    b {
        font-weight: 600;
    }
}
@media screen and (max-width: 1070px) {
    main {
        padding: 1.5rem;
        h1{
            padding: 0px;
        }
        .projlink {
            border-left: none;
            padding-left: 0px;
            font-size: 0.8em; 
        }
        br {
            line-height: 0.2rem;
            display: block;
            margin: 0.5rem 0;
        }
        ul {
            padding-left: 8px;
        }
        .mobile-hidden {
            display: none;
        }
    }
}